<template>
  <v-container class="px-5 mb-10">
    <div class="pa-3 pa-md-0">
      <div v-if="loadPage">
        <div class="text-center" style="height: 60vh; margin-top: 30vh;">
          <mini-loader class="text-center"></mini-loader>
          <p class="primary--text text-body-1">Fetching Terminal details...</p>
        </div>
      </div>
      <div v-if="!loadPage">
        <v-row>
          <v-col cols="12" sm="5" md="3" class="py-0">
            <div class="primary--text">
              <v-btn
                text
                style="background: #BEBFC6;"
                class="rounded-0 white--text rounded"
                small
                @click="$router.go(-1)"
              >
                <v-icon class="mr-1" size="15">arrow_back</v-icon> Back
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row class="mt-2 no-gutters">
              <v-col cols="2" class="py-0"><span class="teller-subtitle">Teller:</span></v-col>
              <v-col cols="9" class="py-0"><span class="teller-text">Maduka Emmanuel Chinonyerem</span></v-col>
            </v-row>
            <v-row class="py-0 no-gutters">
              <v-col cols="2" class="py-0"><span class="teller-subtitle">Terminal ID:</span></v-col>
              <v-col cols="9" class="py-0"><span class="teller-text">{{ $route.params.terminal_id }}</span></v-col>
            </v-row>
            <v-row class="my-0 no-gutters">
              <v-col cols="2"><span class="teller-subtitle">Serial Number:</span></v-col>
              <v-col cols="9"><span class="teller-text">TSs92382</span></v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="no-gutters white elevation-2 mt-6 pa-2">
          <v-col sm="4" cols="6" md="">
            <v-card
              flat
              class="py-3"
            >
              <v-card-text class="py-0 my-0 primary--text"><small>Total Balance</small></v-card-text>
              <v-card-title class="primary--text py-0 my-0 font-weight-bold"><small>₦ 12,000,000</small></v-card-title>
            </v-card>
          </v-col>
          <v-col sm="4" cols="6" md="">
            <v-card
              flat
              class="py-3"
            >
              <v-card-text class="py-0 my-0 primary--text"><small>Total Transactions</small></v-card-text>
              <v-card-title class="primary--text py-0 my-0 font-weight-bold"><small>12</small></v-card-title>
            </v-card>
          </v-col>
          <v-col sm="4" cols="6" md="">
            <v-card
              flat
              class="py-3"
            >
              <v-card-text class="py-0 my-0 primary--text"><small>Total Transaction Amount</small></v-card-text>
              <v-card-title class="primary--text py-0 my-0 font-weight-bold"><small>₦ 120,000</small></v-card-title>
            </v-card>
          </v-col>
          <v-col sm="6" cols="6" md="">
            <v-card
              flat
              class="py-3"
            >
              <v-card-text class="py-0 my-0 primary--text"><small>Total Commission</small></v-card-text>
              <v-card-title class="primary--text py-0 my-0 font-weight-bold"><small>₦ 12,000 <v-chip color="primary--text grey lighten-5" class="pa-1" small label><small>12%</small></v-chip></small></v-card-title>
            </v-card>
          </v-col>
          <v-col sm="6" cols="12" md="">
            <v-card
              flat
              class="text-center"
            >
              <v-card-text class="primary--text pb-0"><small>Target</small></v-card-text>
              <v-chip style="background: #625A42;" small label class="white--text">Behind</v-chip>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-card-text class="primary--text text-h6 py-0">Transactions</v-card-text>
          <v-col cols="7" class="mt-0">
            <v-text-field
              outlined
              label="Search..."
              append-icon="search"
              v-model="search"
              color="primary"
              dense
              style="background: #fff; height: 40px"
            ></v-text-field>
          </v-col>

          <v-row justify="end" align="center">
            <v-btn
              text
              small
              class="inactive pa-5 mr-6"
            >Export</v-btn>
          </v-row>
        </v-row>

        <terminal-transactions :search="search"></terminal-transactions>

      </div>
    </div>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import TerminalTransactions from '@/components/pos/terminal_transactions'
import MiniLoader from '@/components/MiniLoader'
export default {
  components: {
    TerminalTransactions,
    MiniLoader,
  },
  layout: "dashboard",
  transition: "default",
  data() {
    return {
      search: '',
      loadPage: false,
      date_menu: false,
      loading: false,
      agent: {
        user: {
          name: '',
          phone: '',
        },
      },
      terminals: true,
      transactions: false,
      // pos_requests: false,
      assign_teller: false,
      pos_terminals: false,
      selected: [],
      all_requests: [],
      terminal: { text: 'Terminal 1', value: 1 },
      menu1: false,
      dialog: false,
      selected: [],
      form: {
        type: null,
      },
    };
  },

  mounted() {
    this.agentStats()
    this.$parent.loadPage = this.loadPage
  },

  methods: {
    ...mapActions('pos', [
      'requests',
      'agentstatistics'
    ]),

    async agentStats() {
      // this.loadPage = true
      // try {
      //   let response = await this.agentstatistics(this.$route.params)
      //   this.loadPage = false
      //   this.agent = Object.assign({}, response.data)
      // } catch (error) {
      //   this.loadPage = false
      // }
    },
  },

  computed: {
    ...mapGetters("auth", ["user"]),

    tabName() {
      let tabName = ''
      this.terminals
      ? tabName = 'Terminals'
      : this.transactions
      ? tabName = 'Transactions'
      // : this.requests
      // ? tabName = 'POS Requests'
      : this.assign_teller
      ? tabName = 'Assign Teller'
      : ''

      return tabName
    },
  }
};
</script>
<style lang="scss">
.v-application .elevation-5 {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
}
.v-application .elevation-2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.1),
    0px 2px 2px 0px rgba(0, 0, 0, 0.07), 0px 1px 5px 0px rgba(0, 0, 0, 0.06) !important;
}
.v-application .elevation-0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0),
    0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}
.v-menu__content {
  box-shadow: 0 0.04rem 0.4rem rgba(0, 0, 0, 0.15) !important;
  border: 1px solid #dddddd;
}
.t-color tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.inactive {
  background: #E7E7ED !important;
  color: #171B70 !important;
}

.myTable table thead {
  background: #E7E7ED;
}
table thead tr th {
  color: #171B70 !important;
}
.teller-subtitle {
  color: #7A7A7A;
  font-size: 12px;
}
.teller-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 27px;
  color: #171B70;
}
</style>